.cadastro {
  background-color: var(--primary-white);
  width: 95%;
  height: auto;
  box-shadow: 0 0 2em rgba(63, 61, 61, 0.11);
  padding: 20px;
  margin: 70px auto;
  border-radius: 5px;
}

.politicaPrivacidade {
  font-size: 12px;
  color: var(--light);
  display: flex;
  justify-content: center;
  margin-top: 15px; 
  
  .politica {
    margin-left: 5px;
    text-decoration: none;
    color: var(--light);
    font-weight: 600;
  }
}

.cadastro > h2, .cadastro > p {
  text-align: center;
  color: var(--light);
  font-weight: 600;
}

.cadastro > form {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 0% 5%;
}

.formulario {
  font-weight: 500;
  color: var(--light);
}

.inputs {
  padding: 15px;
  border: 1px solid var(--light);
  border-radius: 8px;
  height: 45px;
  width: 100%;
}

.botaoLog {
  display: flex;
  justify-content: center;
}

.botaoCadastro {
  color: var(--primary-white);
  background-color: var(--green);
  border: none;
  border-radius: 5px;
  width: 200px;
  height: 45px;
  margin-top: 70px;
}

@media (min-width: 1000px) {
  .cadastro {
    width: 70%;
    margin: 50px auto;
  }
}

@media (min-width: 1220px) {
  .cadastro {
    width: 50%;
    margin: 50px 15px;
  }

  .cadastro > form {
    margin-top: 80px;
  }

  .botaoCadastro {
    margin-top: 113px;
  }
}

@media (min-width: 1400px) {
  .cadastro {
    width: 700px;
  }
}

@media (min-width: 2000px) {
  .cadastro {
    margin: 100px;
  }
}
