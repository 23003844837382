.saibaMaisComercialBtn {
  z-index: 1;
  width: 210px;
  height: 50px;
  position: absolute;
  top: 105px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media (min-width: 380px) {
  .saibaMaisComercialBtn {
    width: 280px;
    height: 70px;
    top: 136px;
  }
}

@media (min-width: 470px) {
  .saibaMaisComercialBtn {
    top: 172px;
  }
}

@media (min-width: 640px) {
  .saibaMaisComercialBtn {
    top: 240px;
  }
}

@media (min-width: 700px) {
  .saibaMaisComercialBtn {
    top: 270px;
  }
}

@media (min-width: 800px) {
  .saibaMaisComercialBtn {
    top: 300px;
  }
}

@media (min-width: 900px) {
  .saibaMaisComercialBtn {
    top: 310px;
    left: 70%;
  }
}

@media (min-width: 1000px) {
  .saibaMaisComercialBtn {
    top: 375px;
    left: 70%;
  }
}

@media (min-width: 1800px) {
  .saibaMaisComercialBtn {
    width: 400px;
    height: 200px;
    top: 410px;
    left: 68%;
  }
} 
