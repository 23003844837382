.sectOfert {
  padding: 0 5%;
  border: 1px dotted grey;
  background: #f7f7f7;
}
.lblCheck {
  padding: 4px 0 0 2px;
  font-size: 15px;
  color: #cda155;
}
@media (min-width: 320px) {
  .divCheck {
    display: flex;
  }
  .checkInput {
    width: 30px;
    height: 20px;
  }
}

@media (max-width: 767px) {
  .divButton {
    display: flex;
    justify-content: center;
  }
  .divOuro {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .sectOfert {
    display: flex;
    flex-wrap: wrap;
    padding: 0 18px;
  }
  .eachOfert {
    margin-right: 10px;
    width: 250px;
  }
  .divDDD {
    width: 70px;
  }
  .divCel {
    width: 185px;
  }
  .checkInput {
    width: 18px !important;
    height: 18px !important;
  }
}
@media (min-width: 1200px) {
  .divOffContato {
    margin-right: 50px !important;
  }
}

@media (min-width: 1300px) {
  .divOffContato {
    margin-right: 150px !important;
  }
}
@media (min-width: 1400px) {
  .divOffContato {
    margin-right: 180px !important;
  }
}
