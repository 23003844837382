.bannerApp {
  background: #003123;
}

.saibaMaisBtnApp {
  z-index: 1;
  width: 210px;
  height: 50px;
  position: absolute;
  top: 170px;
  left: 25%;
  transform: translateX(-50%);
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.baixarBtnApp {
  z-index: 1;
  width: 210px;
  height: 105px;
  position: absolute;
  top: 220px;
  left: 25%;
  transform: translateX(-50%);
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media (min-width: 380px) {
  .saibaMaisBtnApp {
    width: 280px;
    height: 70px;
    top: 200px;
  }

  .baixarBtnApp {
    top: 260px;
  }
}

@media (min-width: 390px) {
  .baixarBtnApp {
    height: 132px;
    top: 270px;
  }
}

@media (min-width: 450px) {
  .baixarBtnApp {
    top: 299px;
  }
}

@media (min-width: 470px) {
  .saibaMaisBtnApp {
    top: 235px;
  }
}

@media (min-width: 490px) {
  .baixarBtnApp {
    top: 325px;
  }
}

@media (min-width: 520px) {
  .baixarBtnApp {
    top: 350px;
  }
}

@media (min-width: 540px) {
  .saibaMaisBtnApp {
    top: 280px;
  }
}

@media (min-width: 570px) {
  .baixarBtnApp {
    top: 279px;
  }
}

@media (min-width: 640px) {
  .saibaMaisBtnApp {
    top: 240px;
  }
}

@media (min-width: 670px) {
  .baixarBtnApp {
    top: 340px;
  }
}

@media (min-width: 700px) {
  .saibaMaisBtnApp {
    top: 270px;
  }
}

@media (min-width: 770px) {
  .baixarBtnApp {
    height: 200px;
    top: 393px;
    left: 20%;
  }
}

@media (min-width: 800px) {
  .saibaMaisBtnApp {
    top: 300px;
  }
}

@media (min-width: 900px) {
  .saibaMaisBtnApp {
    height: 50px;
    top: 234px;
    left: 20%;
  }
}

@media (min-width: 930px) {
  .baixarBtnApp {
    width: 306px;
    height: 65px;
    top: 295px;
    left: 20%;
  }
}

@media (min-width: 1000px) {
  .saibaMaisBtnApp {
    height: 70px;
    top: 285px;
    left: 15%;
  }

  .baixarBtnApp {
    width: 370px;
    top: 365px;
    left: 20%;
  }
}

@media (min-width: 1400px) {
  .saibaMaisBtnApp {
    height: 95px;
    top: 270px;
    left: 20%;
  }

  .baixarBtnApp {
    top: 368px;
    left: 24%;
  }
}

@media (min-width: 1800px) {
  .saibaMaisBtnApp {
    width: 400px;
    height: 100px;
    top: 285px;
    left: 22%;
  }

  .baixarBtnApp {
    width: 470px;
    height: 100px;
    top: 391px;
  }
}

@media (min-width: 2400px) {
  .baixarBtnApp {
    width: 607px;
    top: 415px;
  }
}
