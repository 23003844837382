.btnAdd {
  width: 100%;
  background-color: #bf8836;
  border-color: #c7a160;
}
.btnAdd:hover {
  background-color: #c7a160c2;
  border-color: #c7a160c2;
}
.btnAdd > a {
  color: #fff;
  text-decoration: none;
}
@media (min-width: 768px) {
  .vw-20 {
    width: 20vw !important;
  }
}
