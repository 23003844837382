.carousel-control-next-icon {
  background-image: url("../../assets/icons/arrow-right-green.png");
  margin-top: 500px;
}
.carousel-control-prev-icon {
  background-image: url("../../assets/icons/arrow-left-green.png");
  margin-top: 500px;
}
@media (min-width: 760px) {
  .carousel {
    width: 800px;
    margin: 0 auto;
  }
  .carousel-inner {
    padding: 0 120px;
  }
  .carousel-control-next,
  .carousel-control-prev {
  }
}

.CarrouselGold > .carousel-inner {
  padding: 0;
}
