/* @media(min-width:1024px){
    .btnTab{
        width: 300px;
        font-size: 18px ;
    }
} */
.btnTab:hover{
  background-color: rgba(62,62,62,0.201);
}
.btnTab{  
  align-items: self-start !important
}
.eachPanel {
  overflow: auto;
  flex: min-content;
}

@media (min-width: 1440px) {
  .eachPanel {
    width: 90% !important;
  }
}
@media (min-width: 1024px) {
  .eachPanel {
    width: 80%;
  }
  .pr-20 {
    padding-right: 20% !important;
  }
  
}
@media (min-width: 768px) {
  .eachPanel {
    width: 70%;
  }
  .btnTab {
    width: 200px;
    font-size: 12px;
    padding: 0 5px !important;
    text-align: start !important;
  }
  .pr-44 {
    padding-right: 44px !important;
  }
  .pr-23 {
    padding-right: 23px !important;
  }
}
@media (max-width: 767px) {
  .btnTab {
    width: 80px;
    font-size: 10px !important;
    align-items: center !important;
  }
}
@media (min-width: 425px) {
  .eachPanel {
    width: 80%;
  }
}
@media (max-width: 424px) {
  .btnTab {
    font-size: 8px !important;
    flex-wrap: wrap;
  }
}
@media(min-width: 1024px){
  .btnTab {
    width: 320px;
    padding-left: 10px !important;
  }
}
