.brindes {
  margin-top: 30px;
  margin-left: 5px;
  width: 100%;
  display: block;
  padding: 0;
  box-sizing: border-box;
}

.brinde-item.unavailable {
  opacity: 0.5;
  pointer-events: none;
  mix-blend-mode: multiply;
}

.brindes-container {
  /* width: 100%; */
  display: flex;
  align-items: center;
  gap: 10px;
}

.brindes-grid {
  width: 85%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  scroll-behavior: smooth;
  gap: 16px;
  padding: 20px;
  overflow: hidden;
}

.divider {
  width: 100%;
}

.brinde-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 calc(150px - 16px);
  max-width: calc(200px - 16px);
  box-sizing: border-box;
  mix-blend-mode: multiply;
  transition: transform 0.1s ease, border 0.1s ease, mix-blend-mode 0.1s ease,
    background-color 0.1s ease;
  background-color: transparent;
}

.brinde-item:hover {
  width: 80px;
  border: 1px solid var(--green);
  border-radius: 16px;
  transform: scale(1.1);
  mix-blend-mode: multiply;
  background-color: transparent;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  filter: invert(32%) sepia(33%) saturate(300%) hue-rotate(180deg);
}

.brinde-overlay {
  p {
    font-size: 10px;
    font-weight: bolder;
    color: var(--green);
    text-align: center;
    text-transform: uppercase;
  }
}

.brinde-image {
  width: 100px;
  height: 100px;
  margin-bottom: 8px;
  margin-top: 15px;
  object-fit: contain;
  mix-blend-mode: multiply;
  background-color: transparent;
  transition: transform 0.1s ease, mix-blend-mode 0.1s ease,
    background-color 0.1s ease;
}

.brinde-image {
  height: 100px;
  object-fit: contain;
  mix-blend-mode: multiply;
}

.brinde-selecionado {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.brinde-info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.brinde-selecionado.acima {
  order: -1;
}

.brinde-selecionado.abaixo {
  order: 1;
}

.brinde-imagem {
  width: 80px;
  height: 80px;
  margin-right: 16px;
  mix-blend-mode: multiply;
  background-color: transparent;
}

.brinde-titulo {
  font-size: 1rem;
  font-weight: 500;
  color: var(--gray);
}

.brinde-detalhes {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brinde-quantidade-valor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 300px;
}

.quantidade,
.valor-brinde {
  font-size: 14px;
  font-weight: 500;
  color: #758080;
}

.remover {
  font-size: 14px;
  font-weight: 500;
  color: #758080;
  margin-right: 175px;
  display: flex;
}

@media (max-width: 768px) {
  .brinde-item {
    flex: 1 0 calc(60% - 16px);
    max-width: calc(53% - 16px);
  }
}

@media (max-width: 480px) {
  .brinde-item {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1000px) {
  .brindes-grid {
    gap: 10px;
  }
}

@media (min-width: 1240px) {
  .brindes {
    width: 158%;
    margin-top: 50px;
  }

  .divider {
    width: 63%;
  }
}

@media (min-width: 1400px) {
  .brindes {
    width: 159%;

    h3 {
      margin-top: 30px;
      margin-left: 50px;
    }
  }

  .brinde-selecionado {
    margin-left: 50px;
  }

  .brinde-detalhes {
    margin-right: 50px;
  }

  .brindes-grid {
    width: 85%;
  }

  .divider {
    width: 60%;
    margin-left: 50px;
  }
}

@media (min-width: 1700px) {
  .brindes {
    h3 {
      margin-left: 100px;
    }
  }

  .brinde-selecionado {
    margin-left: 100px;
  }

  .brinde-detalhes {
    margin-right: 100px;
  }

  .brindes-container {
    margin-left: 70px;
  }

  .divider {
    width: 57%;
    margin-left: 100px;
  }
}

@media (min-width: 1900px) {
  .brindes {
    h3 {
      margin-left: 150px;
    }
  }

  .brinde-selecionado {
    margin-left: 150px;
  }

  .brinde-detalhes {
    margin-right: 150px;
  }

  .brindes-container {
    margin-left: 150px;
  }

  .divider {
    width: 55%;
    margin-left: 150px;
  }
}

@media (min-width: 2100px) {
  .brindes {
    h3 {
      margin-left: 200px;
    }
  }

  .brinde-selecionado {
    margin-left: 200px;
  }

  .brinde-detalhes {
    margin-right: 200px;
  }

  .divider {
    width: 54%;
    margin-left: 200px;
  }
}

@media (min-width: 2300px) {
  .divider {
    width: 56%;
  }
}
