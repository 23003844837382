.senhaInputContainer input[type='password']::-ms-reveal,
.senhaInputContainer input[type='password']::-ms-clear,
.senhaInputContainer input[type='password']::-webkit-contacts-auto-fill-button {
  display: none;
  visibility: hidden;
}

.login {
  background-color: var(--primary-white);
  width: 95%;
  height: auto;
  box-shadow: 0 0 2em rgba(63, 61, 61, 0.11);
  padding: 20px;
  margin: 70px auto;
  border-radius: 5px;
}

.login > h2,
.login > p {
  text-align: center;
  color: var(--light);
  font-weight: 600;
}

.login > form {
  margin: 30px 0 -22px;
  display: flex;
  flex-direction: column;
  padding: 2% 5% 0% 5%;
}

.formulario {
  font-weight: 500;
  color: var(--light);
}

.inputs {
  border: 1px solid var(--light);
  border-radius: 8px;
  padding: 15px;
  height: 45px;
  width: 100%;
  display: block;
}

.senhaInputContainer {
  position: relative;
  .inputs[type='password']::-ms-reveal,
  .inputs[type='password']::-ms-clear,
  .inputs[type='password']::-webkit-contacts-auto-fill-button {
    display: none;
    visibility: hidden;
  }
}

.olhoSenha {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--light);
}

.recuperar {
  font-size: 15px;
  color: var(--light);
  margin-top: -15px;
}

.recuperar > span {
  color: var(--green);
  margin-left: 5px;
  cursor: pointer;
}

.botaoLog {
  display: flex;
  justify-content: center;
}

.botaoLogin {
  color: var(--primary-white);
  background-color: var(--green);
  border: none;
  border-radius: 5px;
  width: 75px;
  height: 45px;
  margin: 30px 0 50px;
}

@media (min-width: 1000px) {
  .login {
    width: 70%;
    margin: 50px auto;
  }
}

@media (min-width: 1220px) {
  .login {
    width: 50%;
    margin: 50px 15px;
  }
}

@media (min-width: 1400px) {
  .login {
    width: 700px;
  }
}

@media (min-width: 2000px) {
  .login {
    margin: 100px;
  }
}
