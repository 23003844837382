.page{
    width: 100%;
    height: 90vh;
}
.b404{
    font-size: 257px;
}
.bt-color{
    background-color: #004133 !important;
}
@media(max-width: 768px){
    .b404{
        font-size: 150px;
    }
}

.not-found__image{
    
}