.css-19kzrtu {
  padding: 0 !important;
}
@media (max-width: 375px) {
  .plot-container {
    padding-left: 10px !important;
  }
}

@media (max-width: 768px) {
  .rect,
  .nsewdrag,
  .drag {
    pointer-events: none !important;
  }
}
