.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popupLogo {
  width: 300px;
  background: var(--green);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  border-radius: 8px 0 0 8px;
}

.popup {
  display: flex;
  background: white;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.popup h2 {
  color: var(--brown);
  font-size: 20px;
  margin-bottom: 0 !important;
}

.popup button {
  color: #000000;
}

.popup p {
  font-size: 16px;
  color: var(--gray);
  margin: 10px 0;
}

.popup a {
  color: var(--gray);
  text-decoration: none;
  font-weight: bold;
}

.popupClose {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popupConteudo {
  padding: 20px;
}

@media (max-width: 500px) {
  .popupLogo {
    display: none;
  }
}
